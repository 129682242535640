<script>
export default {
  name: "LeftMenu",
  props: {
    currentTab: {
      type: Object,
      required: true
    },
    childSelect: {
      type: Object,
      required: false
    }
  },
  mounted() {},
  watch: {},
  methods: {
    /**
     * 切换二级目录
     * @param key
     */
    checkTabItem(key) {
      const newCurrentTab = this.currentTab;
      if (
        newCurrentTab &&
        Object.keys(newCurrentTab).length !== 0 &&
        newCurrentTab.menuChild &&
        newCurrentTab.menuChild.length !== 0
      ) {
        const newChild = newCurrentTab.menuChild;
        newChild.map(item => {
          if (key === item.key) {
            this.checkCurrentTab(key);
            this.$parent.checkChildSelect(item, 2);
          }
        });
      }
    },
    checkCurrentTab(key) {
      const currentCurrentTab = this.currentTab;
      currentCurrentTab.menuChild.map(o => {
        if (key === o.key) {
          o.isCheck = !o.isCheck;
        } else {
          o.isCheck = false;
        }
      });
      if (this.$parent.checkCurrentTab) {
        this.$parent.checkCurrentTab(currentCurrentTab);
      }
    },

    /**
     * 选中的三级页面
     * @param item
     */
    checkItem3(item) {
      this.$parent.checkChildSelect(item, 3);
    }
  }
};
</script>

<template>
  <div>
    <div v-for="item in currentTab.menuChild" :key="item.key">
      <div
        :class="
          childSelect &&
          Object.keys(childSelect).length !== 0 &&
          childSelect.key === item.key &&
          (!item.menuChild || item.menuChild.length === 0)
            ? 'left_menu_item left_menu_item_hover'
            : 'left_menu_item'
        "
        v-on:click="checkTabItem(item.key)"
      >
        <div class="title">{{ item.title }}</div>
        <el-image
          :src="require('@/assets/common/select_close.png')"
          fit="scale-down"
          v-show="
            item.menuChild && item.menuChild.length !== 0 && !item.isCheck
          "
        />
        <el-image
          :src="require('@/assets/common/select_open.png')"
          fit="scale-down"
          v-show="item.menuChild && item.menuChild.length !== 0 && item.isCheck"
        />
      </div>
      <div
        :class="
          o.key === childSelect.key
            ? 'child_item_warp_hover'
            : 'child_item_warp'
        "
        v-if="item.menuChild && item.menuChild.length !== 0 && item.isCheck"
        v-for="o in item.menuChild"
        :key="o.key"
        v-on:click="checkItem3(o)"
      >
        {{ o.title }}
      </div>
      <div class="partition_warp" />
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "../../styles/index.scss";

.left_menu_item {
  background-color: $theme-red;
  height: 78px;
  border-radius: 2px;
  color: #ffffff;
  width: 180px;
  //line-height: 78px;
  text-align: center;
  margin: 0 30px 0 0;
  cursor: pointer;
  display: flex;
  align-items:center;
  justify-content:center;
  .el-image {
    width: 10px;
  }
  .title{
    width: 146px;
    min-height: 20px;
  }
}
.left_menu_item:hover,
.left_menu_item_hover {
  background-color: $theme-orange;
  transform: scale(1.06);
  transition: all 1s;
}
.partition_warp {
  height: 30px;
}
.child_item_warp {
  background-color: $theme-orange;
  height: 38px;
  line-height: 32px;
  width: 180px;
  color: #ffffff;
  padding: 0 20px;
  cursor: pointer;
}
.child_item_warp:hover {
  transform: scale(1.06);
  transition: all 1s;
}
.child_item_warp_hover {
  background-color: #ff8b58;
  height: 38px;
  line-height: 32px;
  width: 180px;
  color: #ffffff;
  padding: 0 20px;
  cursor: pointer;
}

.child_item_warp_hover:hover,
.child_item_warp:hover {
  transform: scale(1.06);
  transition: all 1s;
  background-color: #ff8b58;
}

//.child_item_warp::after {
//  background-color: #ff8b58;
//}
.child_item_warp:hover {
  background-color: #ff8b58;
}
</style>
