<script>
import { generateTitle } from "@/i18n";

export default {
  name: "Banner",
  props: {
    bannerSrc: {
      type: String,
      required: true
    },
    tabs: {
      type: Array,
      required: false
    },
    selTab: {
      type: String,
      required: false
    }
  },
  mounted() {},
  methods: {
    generateTitle,
    tabClick(key) {
      this.$parent.checkTab(key);
    }
  }
};
</script>

<template>
  <div>
    <div class="banner_pc">
      <div class="banner_img">
        <el-image :src="bannerSrc" lazy />
      </div>
    </div>
    <div class="padding-comm-warp art_tab" v-if="tabs && tabs.length !== 0">
      <div
        v-for="item in tabs"
        :key="item.key"
        :class="
          selTab === item.key
            ? 'art_tab_item art_tab_item_hover'
            : 'art_tab_item'
        "
      >
        <div class="title" v-on:click="tabClick(item.key)">
          {{ generateTitle(item.key) }}
          <div class="art_tab_item_but_warp" />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "../../styles/index.scss";

.banner_pc,
.logo,
.banner_img {
  height: 575px !important;
  width: 100%;
  .el-image {
    height: 580px;
    width: 100%;
  }
}

.art_tab {
  display: flex;
  background-color: $theme-red;
  height: 90px;
  padding-top: 0;
  padding-bottom: 0;
  .art_tab_item {
    color: #ffffff;
    width: 180px;
    display: flex;
    justify-content: space-around;
    cursor: pointer;
  }
  .art_tab_item .title {
    letter-spacing: 0.1rem;
    font-size: 1.1rem;
    margin-top: 26px;
    min-width: 98px;
    text-align: center;
  }
  .art_tab_item:hover,
  .art_tab_item_hover {
    background-color: $theme-orange;
    transform: scale(1.06);
    transition: all 1s;
  }
  .art_tab_item_but_warp {
    background: url("../../assets/home/business_bg_white.png") no-repeat;
    background-size: cover; /* 背景图片覆盖整个容器 */
    background-position: center; /* 背景图片居中 */
    height: 8px;
    margin-top: 10px;
  }
}
</style>
