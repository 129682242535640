<script>
import { generateTitle } from "@/i18n";

export default {
  name: "Breadcrumb",
  methods: { generateTitle },
  props: {
    breadcrumbList: {
      type: Array,
      required: true
    }
  },
  watch: {
    // breadcrumbList(newVal, oldVal) {
    //   console.log("User changed", newVal, oldVal);
    // }
  },
  mounted() {}
};
</script>

<template>
  <el-breadcrumb separator-class="el-icon-arrow-right" class="more_breadcrumb">
    <el-breadcrumb-item
      :to="{ path: item.toPath }"
      v-for="item in breadcrumbList"
      :key="item.key"
      >{{ generateTitle(item.key) }}</el-breadcrumb-item
    >
  </el-breadcrumb>
</template>

<style scoped lang="scss">
@import "../../styles/index.scss";

.more_breadcrumb {
  border-bottom: 1px solid $theme-red;
  padding: 40px 0 10px;
  font-size: 1.2rem;
}
</style>
